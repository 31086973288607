.large_header .header-search {
  display: none; }

.large_header .header-title .header-title-3 {
  font-size: 46px; }

.large_header .header-title .small {
  display: block;
  font-size: 18px; }

@media (min-width: 768px) {
  .large_header .header-banner-item {
    min-height: 255px; } }

.large_header .site-footer {
  margin-top: 30px; }

.large_header .footer-links,
.large_header .site-footer-top {
  display: none; }

.large_header .site-footer-bottom {
  padding: 30px 0; }

ul.errors {
  list-style-type: none;
  border: 1px red solid;
  background: rgba(255, 0, 0, 0.1);
  padding: 15px;
  margin: 20px 0;
  color: red; }

.profileTextBlock {
  border: 1px #e6e7e8 solid;
  border-bottom: 5px solid #cc0000;
  margin-top: 32px;
  padding: 25px 30px 50px; }
  .profileTextBlock-title {
    font-size: 24px; }
  .profileTextBlock a .fa {
    font-size: 80%; }
