.large_header {
  .header-search {
    display: none;
  }
  .header-title {
    .header-title-3 {
      font-size: 46px;
    }
    .small {
      display: block;
      font-size: 18px;
    }
  }
  .header-banner-item {
    @media (min-width: 768px) {
      min-height: 255px;
    }
  }
  
  .site-footer {
    margin-top: 30px;
  }
  .footer-links,
  .site-footer-top {
    display: none;
  }
  .site-footer-bottom {
    padding: 30px 0;
  }
}