@import "variables";
@import "header";

.registration {
  &-notice {
  }
}

ul.errors {
  list-style-type: none;
  border: 1px red solid;
  background: rgba(255,0,0,0.1);
  padding: 15px;
  margin: 20px 0;
  color: red;
}

.profileTextBlock {
  border: 1px $gray-border solid;
  border-bottom: 5px solid #cc0000;
  margin-top: 32px;
  padding: 25px 30px 50px;
  &-title {
    font-size: 24px;
  }
  a {
    .fa {
      font-size: 80%;
    }
  }
}